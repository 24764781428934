import React, { useState, useEffect, useContext, useMemo } from "react";
import styles from "../css/AppProducts.module.css";

import AppComponent from "./AppComponent";
import AppBox from "./AppBox";
import ProductsStateBox from "./ProductsStateBox";
import axiosInstance from "../api/AxiosConfig";
import { AppContext } from "../context/AppContext";
import ProductsTable from "./ProductsTable";
import ProductsGraph from "./ProductsGraph"; // Import nové komponenty

const AppProducts = () => {
  const { selectedShop } = useContext(AppContext);

  // Stavové proměnné pro graf (časová řada)
  const [approvedGraph, setApprovedGraph] = useState([]);
  const [suspendedGraph, setSuspendedGraph] = useState([]);
  const [pendingGraph, setPendingGraph] = useState([]);
  const [invalidGraph, setInvalidGraph] = useState([]);
  const [productsStats, setProductsStats] = useState([]);
  
  // Stav pro aktuálně vybraný den – default = poslední položka z API
  const [selectedGraphItem, setSelectedGraphItem] = useState(null);
  // Celkový počet produktů pro aktuální den
  const [productsTotal, setProductsTotal] = useState(0);

  const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedShop) {
        try {
          const responseGraph = await axiosInstance.get(
            `shop/stats/graph-stats/${selectedShop.uuid}/`
          );
          console.log("responseGraph", responseGraph);
          const newGraphData = responseGraph.data;
          
          // Inicializace – nastavíme data s hodnotami 0 pro animaci "od 0"
          setApprovedGraph(newGraphData.map(item => ({ date: item.date, value: 0 })));
          setSuspendedGraph(newGraphData.map(item => ({ date: item.date, value: 0 })));
          setPendingGraph(newGraphData.map(item => ({ date: item.date, value: 0 })));
          setInvalidGraph(newGraphData.map(item => ({ date: item.date, value: 0 })));
          
          // Nastavíme aktuální (selected) den na poslední položku (pokud existuje)
          if (newGraphData.length > 0) {
            const lastItem = newGraphData[newGraphData.length - 1];
            setSelectedGraphItem(lastItem);
            setProductsTotal(
              lastItem.approved_count +
                lastItem.suspended_count +
                lastItem.pending_count +
                lastItem.rejected_count
            );
          }
          
          // Po krátkém zpoždění aktualizujeme data na skutečné hodnoty – spustí se animace
          setTimeout(() => {
            setApprovedGraph(newGraphData.map(item => ({
              date: item.date,
              value: item.approved_count,
            })));
            setSuspendedGraph(newGraphData.map(item => ({
              date: item.date,
              value: item.suspended_count,
            })));
            setPendingGraph(newGraphData.map(item => ({
              date: item.date,
              value: item.pending_count,
            })));
            setInvalidGraph(newGraphData.map(item => ({
              date: item.date,
              value: item.rejected_count,
            })));
          }, 100);
        } catch (error) {
          console.log(error);
        }
        try {
          const responseStats = await axiosInstance.get(
            `shop/stats/product-stats/${selectedShop.uuid}/`
          );
          console.log("responseStats", responseStats);
          const newStatsData = responseStats.data;
          setProductsStats((prevStats) =>
            !isEqual(prevStats, newStatsData) ? newStatsData : prevStats
          );
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [selectedShop]);

  // Sloučíme stavové proměnné pro graf do jednoho pole (časová řada)
  const combinedGraphData = useMemo(() => {
    return approvedGraph.map((item, index) => ({
      date: item.date,
      approved_count: approvedGraph[index]?.value || 0,
      suspended_count: suspendedGraph[index]?.value || 0,
      pending_count: pendingGraph[index]?.value || 0,
      rejected_count: invalidGraph[index]?.value || 0,
    }));
  }, [approvedGraph, suspendedGraph, pendingGraph, invalidGraph]);

  // Handler, který se volá, když se klikne na daný den v grafu
  const handleDayClick = (dayData) => {
    if (dayData) {
      setSelectedGraphItem(dayData);
      const total =
        dayData.approved_count +
        dayData.suspended_count +
        dayData.pending_count +
        dayData.rejected_count;
      setProductsTotal(total);
    }
  };

  return (
    <AppComponent pageEvent={"app_products"}>
      <AppBox>
        <div className={styles.headingBox}>
          <h4 className={styles.heading4}>Souhrný stav</h4>
          <p className={styles.parCount}>Produktů celkem: {productsTotal}</p>
        </div>
        <div className={styles.productsStateBox}>
          <ProductsStateBox
            percentage={
              selectedGraphItem && productsTotal > 0
                ? `${((selectedGraphItem.approved_count / productsTotal) * 100).toFixed(2)} %`
                : "0 %"
            }
            productsCount={selectedGraphItem ? selectedGraphItem.approved_count : 0}
            bgColor={"#22E06C"}
            state={"Aktivní"}
          />
          <ProductsStateBox
            percentage={
              selectedGraphItem && productsTotal > 0
                ? `${((selectedGraphItem.suspended_count / productsTotal) * 100).toFixed(2)} %`
                : "0 %"
            }
            productsCount={selectedGraphItem ? selectedGraphItem.suspended_count : 0}
            bgColor={"#ED7200"}
            state={"Končící platnost"}
          />
          <ProductsStateBox
            percentage={
              selectedGraphItem && productsTotal > 0
                ? `${((selectedGraphItem.pending_count / productsTotal) * 100).toFixed(2)} %`
                : "0 %"
            }
            productsCount={selectedGraphItem ? selectedGraphItem.pending_count : 0}
            bgColor={"#0000FF"}
            state={"Čekající na vyřízení"}
          />
          <ProductsStateBox
            percentage={
              selectedGraphItem && productsTotal > 0
                ? `${((selectedGraphItem.rejected_count / productsTotal) * 100).toFixed(2)} %`
                : "0 %"
            }
            productsCount={selectedGraphItem ? selectedGraphItem.rejected_count : 0}
            bgColor={"#FF0000"}
            state={"Zamítnuto"}
          />
        </div>
        {/* Předáme kombinovaná data a callback do komponenty grafu */}
        <ProductsGraph data={combinedGraphData} onDayClick={handleDayClick} />
      </AppBox>
      <AppBox>
        <div className={styles.headingBox}>
          <h4 className={styles.heading4}>Detailní stav jednotlivých produktů</h4>
        </div>
        <ProductsTable data={productsStats} />
      </AppBox>
    </AppComponent>
  );
};

export default AppProducts;
