import React, { useEffect, useContext, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import useUserDetails from "../hooks/useUserDetails";
import { Helmet } from "react-helmet";
import Modal from "../components/Modal";
import InfoBoxItem from "../components/InfoBoxItem";

import axiosInstance, { clearUserDetailStorage } from "../api/AxiosConfig";

import styles from "../css/ShopOption.module.css";

import { updateCampaign } from "../utils/axios_functions/updateCampaign";
import { getDateWithNextMonth } from "../utils/functions/getDateWithNextMonth";
import { useTranslation } from "react-i18next";

import { FaUsersGear } from "react-icons/fa6";

const ShopOption = () => {
  const navigate = useNavigate();
  const { lng } = useParams();
  const { t } = useTranslation();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  // Přidána možnost "Ukončeno"
  const [selectedStatuses, setSelectedStatuses] = useState({
    Aktivní: true,
    Pozastaveno: true,
    Neaktivní: true,
    Registrace: true,
    Ukončeno: true
  });
  // Nový stav pro výběr řazení – "name" nebo "status"
  const [sortOption, setSortOption] = useState("name");
  // Stav pro vyhledávací řetězec
  const [searchQuery, setSearchQuery] = useState("");

  const {
    checkLoginStatus,
    userData,
    setUserData,
    uuid,
    setUuid,
    appContentType,
    selectedShop,
    setSelectedShop,
    isAdmin,
    setIsUserDataLoading,
    showAdminModal,
    setShowAdminModal,
    infoItems
  } = useContext(AppContext);

  useLayoutEffect(() => {
    setIsUserDataLoading(true);
  }, [setIsUserDataLoading]);

  useEffect(() => {
    checkLoginStatus();
  }, [checkLoginStatus]);

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      setUuid(userData.shops[0].uuid);
      sessionStorage.setItem("x23uU09I098D", userData.shops[0].uuid);
      setSelectedShop(userData?.shops[0]);
    }
  }, [userData, setSelectedShop, setUuid]);

  useUserDetails();

  const handleShopSelect = (selectedShopUuid) => {
    setUuid(selectedShopUuid);
    setSelectedShop(
      userData?.shops.find((oneShop) => oneShop.uuid === selectedShopUuid)
    );
    sessionStorage.setItem("x23uU09I098D", selectedShopUuid);
  };

  const handleSubmit = () => {
    if (selectedShop.status_create === "end") {
      window.scrollTo(0, 0);
      navigate(`/${lng}/app/${appContentType}/`);
    } else {
      window.scrollTo(0, 0);
      navigate(`/${lng}/app/register-shop/`);
    }
  };

  const handleNew = () => {
    sessionStorage.removeItem("x23uU09I098D");
    setSelectedShop("");
    setUuid("");
    window.scrollTo(0, 0);
    navigate(`/${lng}/app/register-shop/`);
  };

  const removeProtocol = (url) => {
    return url.replace(/^https?:\/\/(www\.)?/, "");
  };

  const onTurnOffShopClick = () => {
    setShowAdminModal(false);
    setShowDeleteConfirmation(true);
  };

  const handleTurnOffShop = async () => {
    try {
      if (selectedShop?.campaign[0]?.campaign_id) {
        setIsUserDataLoading(true);
        await updateCampaign(selectedShop.campaign[0].campaign_id, {
          campaign_status: "paused"
        });
        await axiosInstance.patch(`shop/update/${selectedShop.uuid}/`, {
          end_date: getDateWithNextMonth()
        });
        const response = await axiosInstance.get(`user/detail/`);
        setUserData(response.data);
      } else {
        setIsUserDataLoading(true);
        await axiosInstance.patch(`shop/update/${selectedShop.uuid}/`, {
          end_date: getDateWithNextMonth()
        });
        const response = await axiosInstance.get(`user/detail/`);
        setUserData(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsUserDataLoading(false);
    }
  };

  // Pomocná funkce pro získání statusu obchodu
  const getShopStatus = (oneShop) => {
    if (oneShop.status_create !== "end") return "Registrace";
    const pmaxCampaign = oneShop?.campaign.find((oneCampaign) =>
      oneCampaign.campaign_type.toLowerCase().includes("pmax")
    );
    if (pmaxCampaign) {
      if (pmaxCampaign.campaign_status === "active") return "Aktivní";
      else if (pmaxCampaign.campaign_status === "paused") return "Pozastaveno";
      else return "Neaktivní";
    }
    return "Neaktivní";
  };

  // Handler pro změnu checkboxů
  const handleStatusChange = (e) => {
    const { value, checked } = e.target;
    setSelectedStatuses((prev) => ({
      ...prev,
      [value]: checked
    }));
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>Výběr obchodu - PermoniQ</title>
      </Helmet>
      <section className="section choose-shop">
        <article className="container" style={{ maxWidth: "50%" }}>
          <form
            className="form"
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="box">
              <div
                className="select-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column"
                }}
              >
                {userData?.email === "info@permoniq.com" ? (
                  <div className={styles.headingBox}>
                    <h2>{t("shopOption.heading")}</h2>
                    {/* <FaUsersGear
                      className={styles.usersBtn}
                      onClick={() => {
                        navigate(`/${lng}/app/users/`);
                      }}
                    /> */}
                  </div>
                ) : (
                  <h2>{t("shopOption.heading")}</h2>
                )}
                {userData?.email === "info@permoniq.com" && (
                  <div className={styles.sortAndSearchContainer}>
                    <input
                      type="text"
                      className={styles.searchInput}
                      placeholder="Vyhledávání"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <select
                      className={styles.sortSelect}
                      id="sortSelect"
                      value={sortOption}
                      onChange={(e) => setSortOption(e.target.value)}
                    >
                      <option value="name">{t("shopOption.valueName")}</option>
                      <option value="status">
                        {t("shopOption.valueState")}
                      </option>
                    </select>
                  </div>
                )}
                <ol
                  className="shop-list"
                  style={{
                    width: "90%",
                    listStyleType: "none",
                    padding: 0,
                    maxHeight: "300px",
                    overflowY: "auto"
                  }}
                >
                  {Object.keys(userData).length > 0 &&
                    userData.shops
                      .filter((oneShop) => {
                        // Filtrování podle vyhledávacího řetězce – kontrola webu a emailů v oneShop.users
                        if (searchQuery) {
                          const query = searchQuery.toLowerCase();
                          const website = removeProtocol(
                            oneShop.website
                          ).toLowerCase();
                          const usersMatch =
                            oneShop.users &&
                            oneShop.users.some(
                              (user) =>
                                user.email &&
                                user.email.toLowerCase().includes(query)
                            );
                          if (!website.includes(query) && !usersMatch) {
                            return false;
                          }
                        }
                        const baseStatus = getShopStatus(oneShop);
                        const shopEndDate = oneShop.end_date
                          ? new Date(oneShop.end_date)
                          : null;
                        const status = shopEndDate ? "Ukončeno" : baseStatus;
                        return selectedStatuses[status];
                      })
                      .sort((a, b) => {
                        const getDisplayStatus = (shop) => {
                          const baseStatus = getShopStatus(shop);
                          const shopEndDate = shop.end_date
                            ? new Date(shop.end_date)
                            : null;
                          return shopEndDate ? "Ukončeno" : baseStatus;
                        };
                        if (sortOption === "name") {
                          return removeProtocol(a.website).localeCompare(
                            removeProtocol(b.website)
                          );
                        } else {
                          return getDisplayStatus(a).localeCompare(
                            getDisplayStatus(b)
                          );
                        }
                      })
                      .map((oneShop, index) => {
                        let baseStatus = getShopStatus(oneShop);
                        const now = new Date();
                        const shopEndDate = oneShop.end_date
                          ? new Date(oneShop.end_date)
                          : null;
                        let status = shopEndDate ? "Ukončeno" : baseStatus;

                        let statusBackgroundColor;
                        let statusTextColor;
                        let statusBorder = "none";

                        if (status === "Aktivní") {
                          statusBackgroundColor = "#22E06C";
                        } else if (status === "Pozastaveno") {
                          statusBackgroundColor = "#EFEFEF";
                          statusTextColor = "#555555";
                        } else if (status === "Neaktivní") {
                          statusBackgroundColor = "#999999";
                          statusTextColor = "#ffffff";
                        } else if (status === "Registrace") {
                          statusBackgroundColor = "#fff";
                          statusTextColor = "#000";
                          statusBorder = "2px solid #DAFD82";
                        } else if (status === "Ukončeno") {
                          statusBackgroundColor = "#FFC4C4";
                          statusTextColor = "#000";
                        }

                        return (
                          <li
                            key={oneShop.uuid + index}
                            onClick={() => handleShopSelect(oneShop.uuid)}
                            style={{
                              cursor: "pointer",
                              borderRadius: "4px",
                              margin: "5px 0",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center"
                            }}
                          >
                            <div
                              className="item shop_option_item"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center",
                                backgroundColor:
                                  uuid === oneShop.uuid
                                    ? "#e0e0e0"
                                    : "transparent",
                                margin: 0,
                                padding: 0
                              }}
                            >
                              <span className="item__ico"></span>
                              <span
                                className="item__title"
                                style={{ flexGrow: 1, padding: 0, margin: 0 }}
                              >
                                {removeProtocol(oneShop.website)}
                                {shopEndDate && shopEndDate > now && (
                                  <span
                                    style={{
                                      marginLeft: "10px",
                                      fontSize: "0.9em",
                                      color: "#999"
                                    }}
                                  >
                                    ({shopEndDate.toLocaleDateString()})
                                  </span>
                                )}
                              </span>
                              <span
                                className="item__control"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  margin: "0 !important",
                                  padding: 0
                                }}
                              >
                                <label
                                  className="switcher"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "5px 10px",
                                    width: "120px"
                                  }}
                                >
                                  <span
                                    className="switcher__label"
                                    style={{
                                      backgroundColor: statusBackgroundColor,
                                      color: statusTextColor,
                                      padding: "5px 10px",
                                      textAlign: "center",
                                      width: "100%",
                                      borderRadius: "100vh",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      border: statusBorder
                                    }}
                                  >
                                    {status}
                                  </span>
                                </label>
                              </span>
                            </div>
                          </li>
                        );
                      })}
                </ol>
                {userData?.email === "info@permoniq.com" && (
                  <div className={styles.checkboxContainer}>
                    {[
                      "Aktivní",
                      "Pozastaveno",
                      "Neaktivní",
                      "Registrace",
                      "Ukončeno"
                    ].map((statusOption) => (
                      <label
                        key={statusOption}
                        className={`checkbox ${styles.checkboxLabel}`}
                      >
                        <input
                          type="checkbox"
                          name="checkbox"
                          value={statusOption}
                          checked={selectedStatuses[statusOption]}
                          onChange={handleStatusChange}
                        />
                        {statusOption}
                        <span className="checkbox__checker"></span>
                      </label>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {isAdmin ? (
              <div className="btn__wrapper">
                <div className="register__submit">
                  <p>
                    <button className="btn" onClick={() => handleNew()}>
                      Nový
                    </button>
                  </p>
                </div>
                <div className="register__submit">
                  <p>
                    <button className="btn" type="submit">
                      Vybrat
                    </button>
                  </p>
                </div>
              </div>
            ) : (
              <div className="register__submit">
                <p>
                  <button className="btn" type="submit">
                    Vybrat
                  </button>
                </p>
              </div>
            )}
          </form>
        </article>
      </section>
      {userData.email === "info@permoniq.com" && showAdminModal && (
        <Modal
          title="Informace o obchodu"
          cancelText="Zavřít"
          confirmText=""
          onClose={() => setShowAdminModal(false)}
          isDelete={!selectedShop?.end_date}
          onDelete={onTurnOffShopClick}
        >
          {infoItems.map((item, index) => (
            <InfoBoxItem key={index} label={item.label} value={item.value} />
          ))}
        </Modal>
      )}
      {showDeleteConfirmation && (
        <Modal
          title={"Vypnutí obchodu"}
          cancelText="Zpět"
          confirmText=""
          isDelete={true}
          onDelete={() => {
            handleTurnOffShop();
            setShowDeleteConfirmation(false);
          }}
          onClose={() => {
            setShowDeleteConfirmation(false);
            setShowAdminModal(true);
          }}
        >
          <p>{`Opravdu chcete vypnout obchod ${selectedShop?.name}?`}</p>
          <div className={styles.deletePassBox}></div>
        </Modal>
      )}
    </>
  );
};

export default ShopOption;
