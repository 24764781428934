import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styles from "../css/ProductsGraph.module.css";

const ProductsGraph = ({ data, onDayClick }) => {
  // Obslužná funkce pro kliknutí – pokud je aktivní payload, zavolá callback s daty daného dne
  const handleClick = (e) => {
    if (e && e.activePayload && e.activePayload.length > 0) {
      const clickedData = e.activePayload[0].payload;
      if (onDayClick) onDayClick(clickedData);
    }
  };

  return (
    <div className={styles.container}>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data} onClick={handleClick} style={{cursor: "pointer"}}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Line
            strokeWidth={4}
            type="monotone"
            name="Aktivní"
            dataKey="approved_count"
            stroke="#22E06C"
            legendType="circle"
          />
          <Line
            strokeWidth={4}
            type="monotone"
            name="Končící platnost"
            dataKey="suspended_count"
            stroke="#ED7200"
            legendType="circle"
          />
          <Line
            strokeWidth={4}
            type="monotone"
            name="Čekající na vyřízení"
            dataKey="pending_count"
            stroke="#0000FF"
            legendType="circle"
          />
          <Line
            strokeWidth={4}
            type="monotone"
            name="Zamítnuto"
            dataKey="rejected_count"
            stroke="#FF0000"
            legendType="circle"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ProductsGraph;
