import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import HeroSection from "../components/HeroSection";
import BenefitsSection from "../components/BenefitsSection";
import CertifySection from "../components/CertifySection";
import ComparisonSection from "../components/ComparisonSection";
import SetupSection from "../components/SetupSection";
import ForWhomSection from "../components/ForWhomSection";
import ReferenceSection from "../components/ReferenceSection";
import FAQSection from "../components/FAQSection";
import AdvisioSection from "../components/AdvisioSection";
import TryPermoniQSection from "../components/TryPermoniqSection";
import ModalConsultation from "../components/ModalConsultation";

import useUserDetails from "../hooks/useUserDetails";
import { AppContext } from "../context/AppContext";

const sectionsForCheck = [
  "benefits",
  "what-it-can-do",
  "comparison",
  "how-to-set-up",
  "for-whom",
  "references",
  "faq",
  "advisio",
  "try-free",
  "consultation"
];

const Home = () => {
  const { t } = useTranslation();
  const { isLogged } = useContext(AppContext);
  const timeoutRef = useRef(null);
  // Místo jediného booleanu použijeme objekt, kde budeme mít záznam o odeslaných eventech
  const eventSentRef = useRef({});

  useUserDetails();

  useEffect(() => {
    if (window.location.hash) {
      let hash = window.location.hash.replace("/", "");
      try {
        document.querySelector(hash).scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      } catch (error) {
        console.log("section doesn't exist");
      }
    }
  }, []);

  useEffect(() => {
    const sections = document.querySelectorAll("section[id], .how");
    const visibleSections = new Set();

    const observer = new IntersectionObserver(
      (entries) => {
        // Aktualizace setu viditelných sekcí
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            visibleSections.add(entry.target.id);
          } else {
            visibleSections.delete(entry.target.id);
          }
        });

        // Vyčistíme předchozí timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        // Najdeme první sekci podle pořadí v DOM, která je viditelná
        const firstVisibleSection = Array.from(sections).find((section) =>
          visibleSections.has(section.id)
        );

        if (
          firstVisibleSection &&
          sectionsForCheck.includes(firstVisibleSection.id)
        ) {
          timeoutRef.current = setTimeout(() => {
            let id = firstVisibleSection.id;
            if (id === "how") {
              id = "how-to-set-up";
            }
            window.history.replaceState(null, "", `#${id}`);
            // Sestavíme název eventu (např. lp_benefits)
            const eventName = `lp_${id}`;
            // Odeslání eventu jen pokud ještě nebyl odeslán pro toto jméno
            if (!eventSentRef.current[eventName]) {
              window.dataLayer.push({
                event: eventName
              });
              eventSentRef.current[eventName] = true;
            }
          }, 600);
        } else {
          // Pokud není žádná sekce viditelná, nebo první viditelná není v sectionsForCheck,
          // odstraníme hash z URL
          timeoutRef.current = setTimeout(() => {
            const currentUrl = new URL(window.location.href);
            const hash = currentUrl.hash.replace("#", "");
            const sectionToRemove = sectionsForCheck.find((section) =>
              hash.startsWith(section)
            );
            if (sectionToRemove) {
              const remainingHash = hash
                .replace(sectionToRemove, "")
                .replace(/^#/, "");
              const newUrl = `${currentUrl.pathname}${currentUrl.search}${
                remainingHash ? `#${remainingHash}` : ""
              }`;
              window.history.replaceState(null, "", newUrl);
            }
          }, 600);
        }
      },
      {
        threshold: 0.7
      }
    );

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>PermoniQ = online reklama na jeden kliQ</title>
      </Helmet>
      <HeroSection id={t("paths.firstSection")} />
      <BenefitsSection id={t("paths.benefits")} />
      <CertifySection id={t("paths.certify")} />
      <ComparisonSection id={t("paths.comparison")} />
      <SetupSection id={t("paths.howToSetUp")} />
      <ForWhomSection id={t("paths.forWhom")} />
      <ReferenceSection id={t("paths.references")} />
      <FAQSection id={t("paths.faq")} />
      <AdvisioSection id={t("paths.advisio")} />
      {!isLogged && <TryPermoniQSection id={t("paths.tryFree")} />}
      <ModalConsultation id={t("paths.consultation")} />
    </>
  );
};

export default Home;
