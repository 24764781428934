import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import axiosInstance from "../api/AxiosConfig";
import styles from "../css/Users.module.css";
import ModalUsers from "../components/ModalUsers";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const navigate = useNavigate();

  const { setIsUserDataLoading } = useContext(AppContext);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filter, setFilter] = useState("");

  // Přidáme stav pro výběr fází (stages) – defaultně jen "Cold" je vybráno
  const [selectedStages, setSelectedStages] = useState({
    Cold: true,
    Warm: false,
    Hot: false,
    Top: false,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [shops, setShops] = useState([]);

  useEffect(() => {
    navigate("/cs/");
  }, []);

  useEffect(() => {
    getUsers();
  }, []);

  // Filtrace uživatelů zohledňující vyhledávání a checkbox filtry
  useEffect(() => {
    const filtered = users
      .filter((user) => {
        // Filtrovat podle emailu
        if (!user.email.toLowerCase().includes(filter.toLowerCase())) {
          return false;
        }
        // Získáme uživatelovu fázi a ověříme, zda je vybrána v checkboxech
        const stage = getUserStage(user.user_value);
        return selectedStages[stage];
      })
      .sort((a, b) => a.email.localeCompare(b.email));
    setFilteredUsers(filtered);
  }, [filter, users, selectedStages]);

  // Načtení seznamu uživatelů
  const getUsers = async () => {
    try {
      const response = await axiosInstance.get("users/");
      const sortedUsers = response.data.sort((a, b) =>
        a.email.localeCompare(b.email)
      );
      setUsers(sortedUsers);
    } catch (error) {
      console.log("Error getting users!", error);
    } finally {
      setIsUserDataLoading(false);
    }
  };

  // Načtení detailu vybraného uživatele (obchody atd.)
  const getUserDetails = async (userId) => {
    setIsUserDataLoading(true);
    try {
      const response = await axiosInstance.get(`user/detail/${userId}/`);
      setShops(response.data.shops || []);
    } catch (error) {
      console.log("Error getting user details!", error);
      setShops([]);
    } finally {
      setIsUserDataLoading(false);
    }
  };

  // Převede číselnou hodnotu user_value do textové fáze
  const getUserStage = (value) => {
    if (value <= 1000) return "Cold";
    if (value <= 2000) return "Warm";
    if (value <= 3000) return "Hot";
    return "Top";
  };

  // Obrácená konverze: z textu do čísel
  const stageToValue = (stage) => {
    switch (stage) {
      case "Cold":
        return 0;
      case "Warm":
        return 1500;
      case "Hot":
        return 2500;
      default:
        return 3500; // Top
    }
  };

  // PATCH request: po změně user_value
  const onUpdateValue = async (id, newStage) => {
    setIsUserDataLoading(true);
    try {
      const numericValue = stageToValue(newStage);
      await axiosInstance.patch(`users/${id}/`, {
        user_value: numericValue,
      });
      setIsModalOpen(false);
      getUsers();
    } catch (error) {
      console.log("Error updating user value", error);
    } finally {
      setIsUserDataLoading(false);
    }
  };

  // Klik na řádek s uživatelem
  const handleUserClick = (user) => {
    setSelectedUser(user);
    getUserDetails(user.id);
    setIsModalOpen(true);
  };

  const formatDate = (dateStr) =>
    new Intl.DateTimeFormat("cs-CZ", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    }).format(new Date(dateStr));

  // Filtrace obchodů, které patří vybranému uživateli
  const userShops = selectedUser
    ? shops.filter((shop) => shop.users.some((u) => u.id === selectedUser.id))
    : [];

  // Handler změny checkboxu pro filtrace podle fází
  const handleStageChange = (e) => {
    const { value, checked } = e.target;
    setSelectedStages((prev) => ({
      ...prev,
      [value]: checked,
    }));
  };

  return (
    <>
      <section className={`${"need-mt"} ${styles.usersSection}`}>
        <h2 className={styles.title}>Vyberte uživatele</h2>
        <div className={styles.sortAndSearchContainer}>
          <input
            type="text"
            placeholder="Vyhledávání"
            className={styles.searchInput}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <div className={styles.userListBox}>
          {filteredUsers.length > 0 ? (
            filteredUsers.map((user) => (
              <div
                key={user.id}
                className={styles.userRow}
                onClick={() => handleUserClick(user)}
              >
                <span className={styles.userEmail}>{user.email}</span>
                {(user.user_value || user.user_value === 0) && (
                  <span className={styles.userValue}>
                    {getUserStage(user.user_value)}
                  </span>
                )}
              </div>
            ))
          ) : (
            <p className={styles.noUsers}>Nic jsem nenašel...</p>
          )}
        </div>
        <div className={styles.checkboxContainer}>
          {["Cold", "Warm", "Hot", "Top"].map((stageOption) => (
            <label
              key={stageOption}
              className={`checkbox ${styles.checkboxLabel}`}
            >
              <input
                type="checkbox"
                name="stage"
                value={stageOption}
                checked={selectedStages[stageOption]}
                onChange={handleStageChange}
              />
              {stageOption}
              <span className="checkbox__checker"></span>
            </label>
          ))}
        </div>
      </section>

      {isModalOpen && selectedUser && (
        <ModalUsers
          userId={selectedUser.id}
          title={selectedUser.email}
          userValue={selectedUser.user_value}
          userRegistered={formatDate(selectedUser.registered_at)}
          shops={userShops}
          onClose={() => setIsModalOpen(false)}
          onConfirm={onUpdateValue} // volá se s (userId, newStage)
          confirmText=""
          cancelText="Zavřít"
          getUserStage={getUserStage}
          formatDate={formatDate}
        />
      )}
    </>
  );
};

export default Users;
