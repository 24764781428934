import React, { useState, useRef, useContext, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

import { AppContext } from "../context/AppContext";

import { updateCampaign } from "../utils/axios_functions/updateCampaign";

import axiosInstance, { clearUserDetailStorage } from "../api/AxiosConfig";

import useUserAppDetails from "../hooks/useUserAppDetails";

import ModalLoading from "./ModalLoading";
import SimpleAlert from "./SimpleAlert";
import { useNavigate, useParams } from "react-router-dom";

import styles from "../css/AppSettingsStyle.module.css";

import tooltipInfoIcon from "../img/tooltip.svg";
import deleteIcon from "../img/icons/delete.svg";
import { ReactSVG } from "react-svg";
import Tooltip from "./Tooltip";
import Switcher from "./buttons/Switcher";
import Modal from "./Modal";
import useUserDetails from "../hooks/useUserDetails";
import deleteAllDatabases from "../utils/functions/deleteAllDatabases";

import sendEvent from "../utils/functions/sendEvent";

import { FaRegCopy } from "react-icons/fa";
import DpTutor from "./DpTutor";
import FeedTutor from "./FeedTutor";
import { getDateWithNextMonth } from "../utils/functions/getDateWithNextMonth";

const currencySettings = {
  CZK: { symbol: "Kč", minLimit: 300, maxLimit: 20000 },
  EUR: { symbol: "€", minLimit: 12, maxLimit: 800 },
  HUF: { symbol: "Ft", minLimit: 4700, maxLimit: 320000 },
  RON: { symbol: "lei", minLimit: 60, maxLimit: 4000 },
  PLN: { symbol: "zł", minLimit: 50, maxLimit: 3300 },
};

const billingCountries = [
  "Česká republika",
  "Slovensko",
  "Maďarsko",
  "Polsko",
  "Rumunsko",
];

const AppSettings = () => {
  const { t } = useTranslation();
  const { lng } = useParams();
  const navigate = useNavigate();

  const tooltipIconRef = useRef(null); // Ref pro ikonu
  const prevShopUuidRef = useRef(null);

  const [shopForDisplay, setShopForDisplay] = useState({});
  const [codeVisible, setCodeVisible] = useState(true);
  const [rangeValue, setRangeValue] = useState(20);
  const [prevRangValue, setPrevRangeValue] = useState(null);
  const [limitValue, setLimitValue] = useState(100);
  const [prevLimitValue, setPrevLimitValue] = useState(null);
  const [productFeedUrl, setProductFeedUrl] = useState("");
  const [managerEmail, setManagerEmail] = useState("exmaple@domain.com");
  const maxLength = 10000;
  const [descValue, setDescValue] = useState("");
  const [currency, setCurrency] = useState("CZK");
  const [result, setResult] = useState("");
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [campaignStatus, setCampaignStatus] = useState("paused");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pendingStatus, setPendingStatus] = useState(null);
  const [newUserMail, setNewUserMail] = useState("");
  const [message, setMessage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);
  const [showDpTutor, setShowDpTutor] = useState(false);
  const [showFeedTutor, setShowFeedTutor] = useState(false);
  const [userList, setUserList] = useState([]);
  const [feedUrlError, setFeedUrlError] = useState("");

  const copyButtonRef = useRef(null);

  const currentCurrencySettings =
    currencySettings[currency] || currencySettings["CZK"];

  const {
    code,
    setCode,
    userData,
    setUserData,
    uuid,
    selectedShop,
    setSelectedShop,
    pno,
    setPno,
    shopName,
    merchantId,
    isUserDataLoading,
    setIsUserDataLoading,
    campaignId,
    setCampaignId,
    budget,
    setBudget,
    alertMessage,
    isAlertVisible,
    showAlert,
    closeAlert,
    setActiveItem,
    setAppContentType,
    setShopStatus,
  } = useContext(AppContext);

  const [countryBilling, setCountryBilling] = useState(
    selectedShop?.country_billing
  );
  const [ico, setIco] = useState(selectedShop?.ico);
  const [companyName, setCompanyName] = useState(selectedShop?.name);
  const [street, setStreet] = useState(selectedShop?.street);
  const [city, setCity] = useState(selectedShop?.city);
  const [postalCode, setPostalCode] = useState(selectedShop?.postal_code);
  const [dic, setDic] = useState(selectedShop?.dic);
  const [emailBilling, setEmailBilling] = useState(selectedShop?.email_billing);

  useUserAppDetails();

  useEffect(() => {
    sendEvent("app_settings");
  }, []);

  // uuid se propíše do sessionStorage případě, že se zde uživatel proklikne z nastavení kampaně při chybějícím description
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    // Převod query parametrů na objekt
    const paramsObject = Object.fromEntries(queryParams.entries());

    if (paramsObject?.uuid) {
      // Uložení uuid do sessionStorage
      sessionStorage.setItem("x23uU09I098D", paramsObject?.uuid);

      // Odstranění parametru 'uuid' z URL
      queryParams.delete("uuid");

      // Aktualizace URL bez znovunačtení (pouze pokud zbydou nějaké parametry)
      const newQueryString = queryParams.toString();
      const newUrl = newQueryString
        ? `${window.location.pathname}?${newQueryString}`
        : window.location.pathname;

      window.history.replaceState(null, "", newUrl);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      setShopForDisplay(
        userData?.shops?.find((oneShop) => oneShop.uuid === uuid)
      );
    }
  }, [uuid, shopForDisplay]);

  useEffect(() => {
    if (selectedShop?.users && selectedShop.users.length > 0) {
      // If the selected shop has changed, reset the user list.
      if (prevShopUuidRef.current !== selectedShop.uuid) {
        setUserList(selectedShop.users);
        prevShopUuidRef.current = selectedShop.uuid;
      } else {
        // If it's the same shop, append only new users (avoiding duplicates)
        setUserList((prevState) => {
          const newUsers = selectedShop.users.filter(
            (newUser) =>
              !prevState.some((existingUser) => existingUser.id === newUser.id)
          );
          return [...prevState, ...newUsers];
        });
      }
    } else {
      // If there are no users, clear the user list and reset the ref.
      setUserList([]);
      prevShopUuidRef.current = null;
    }
  }, [selectedShop, selectedShop.users]);

  useEffect(() => {
    if (shopForDisplay && Object.keys(shopForDisplay).length > 0) {
      setProductFeedUrl(shopForDisplay.feed);
      setDescValue(shopForDisplay.description);
    } else if (selectedShop) {
      setProductFeedUrl(selectedShop.feed);
      setDescValue(selectedShop.description);
    }
  }, [shopForDisplay, selectedShop]);

  useEffect(() => {
    if (pno < 5) {
      setRangeValue(5);
    } else {
      setRangeValue(pno);
    }
  }, [pno]);

  const validateUrl = (url) => {
    const urlPattern = /^https?:\/\/.*\..*/i;
    return urlPattern.test(url);
  };

  useEffect(() => {
    setLimitValue(budget);
  }, [budget]);

  useEffect(() => {
    if (selectedShop && selectedShop?.google_ads?.client_currency) {
      setCurrency(selectedShop.google_ads.client_currency);
    }
  }, [selectedShop]);

  useEffect(() => {
    if (Object.keys(selectedShop).length > 0) {
      if (selectedShop?.campaign.length > 0) {
        let pmaxCampaign = selectedShop.campaign.find((campaign) =>
          campaign.name.toLowerCase().includes("pmax")
        );
        pmaxCampaign && setCampaignId(pmaxCampaign.campaign_id);
        if (pmaxCampaign && pmaxCampaign.campaign_status === "active") {
          setCampaignStatus("active");
        } else if (pmaxCampaign && pmaxCampaign.campaign_status === "paused") {
          setCampaignStatus("paused");
        } else {
          setCampaignStatus("deactived");
        }
      } else {
        setCampaignStatus("deactived");
      }
    } else {
      setCampaignStatus("deactived");
    }
  }, [selectedShop]);

  const handleCodeCopy = () => {
    navigator.clipboard.writeText(code).then(() => {
      showAlert(t("appSettings.infoMessages.codeCopiedInfoMsg"), "info");
      copyButtonRef.current.blur();
    });
  };

  const currencyType = () => {
    if (selectedShop && selectedShop?.currency) {
    }
  };

  const handleRangeChange = (e) => {
    setRangeValue(e.target.value);
  };

  const handleFeedUrlChange = (e) => {
    const newUrl = e.target.value;
    setProductFeedUrl(newUrl);

    if (!validateUrl(newUrl)) {
      setFeedUrlError(t("appSettings.errorMessages.invalidUrlErrorMessage"));
    } else {
      setFeedUrlError("");
    }
  };

  const handleLimitChange = (e) => {
    setLimitValue(e.target.value);
  };

  const handleManagerEmailChange = (e) => {
    setManagerEmail(e.target.value);
  };

  const handleUserAdd = async (uuid, email) => {
    setMessage(t("appSettings.infoMessages.addingNewUser"));
    setIsUserDataLoading(true);

    // Prevent adding the protected user
    if (email === "info@permoniq.com") {
      console.warn("This user cannot be added.");
      return;
    }

    try {
      const response = await axiosInstance.post(`shop/add-user/${uuid}/`, {
        email: email,
      });

      // Prevent adding if the user is already part of the shop
      if (response.data.message === "User is already part of this shop.") {
        showAlert(t("appSettings.errorMessages.userAlreadyExists"), "danger");
        return;
      }
      clearUserDetailStorage();
      await axiosInstance.get(`shop/detail/${selectedShop.uuid}/`);

      // Add the new user to the userList if not already present
      setUserList((prevUserList) => {
        if (!prevUserList.some((user) => user.email === email)) {
          return [...prevUserList, { email, id: response.data.id }];
        }
        return prevUserList;
      });
      showAlert(t("appSettings.infoMessages.userAdded"), "info");
      setNewUserMail("");
    } catch (error) {
      showAlert(t("appSettings.infoMessages.userAdded"), "info");
      return; // Stop the process if there's an error
    } finally {
      setTimeout(() => {
        setIsUserDataLoading(false);
      }, 1000);
    }
  };

  const handleUserRemove = async (uuid, email) => {
    // Prevent deletion of the protected user
    if (email === "info@permoniq.com") {
      console.warn("This user cannot be deleted.");
      return;
    }

    setMessage(t("appSettings.infoMessages.removingUser"));
    setIsUserDataLoading(true);

    try {
      const response = await axiosInstance.post(`shop/remove-user/${uuid}/`, {
        email: email,
      });

      if (response.data.message === "User is not part of this shop.") {
        showAlert(t("appSettings.errorMessages.userIsNotHere"), "danger");
        clearUserDetailStorage();
        setUserList([]);
        return;
      }

      // deleteAllDatabases();
      clearUserDetailStorage();
      await axiosInstance.get(`shop/detail/${selectedShop.uuid}/`);

      // Remove the user from the userList
      setUserList((prevUserList) =>
        prevUserList.filter((user) => user.email !== email)
      );
      showAlert(t("appSettings.infoMessages.userRemoved"), "info");
    } catch (error) {
      console.log("test", error);
      if (error.response.data.message === "User is not part of this shop.") {
        showAlert(t("appSettings.errorMessages.userIsNotHere"), "danger");
        clearUserDetailStorage();
        setUserList((prevUserList) =>
          prevUserList.filter((user) => user.email !== email)
        );
        return;
      }
      console.error("Error removing user:", error);
    } finally {
      setShowDeleteModal(false);
      setTimeout(() => {
        setIsUserDataLoading(false);
      }, 1000);
    }
  };

  const toggleCodeVisibility = () => {
    setCodeVisible(!codeVisible);
  };

  const validateFeed = async (event, attempt = 1) => {
    // Při prvním volání zamezíme defaultnímu chování formuláře
    if (attempt === 1 && event) {
      event.preventDefault();
    }

    if (!validateUrl(productFeedUrl)) {
      showAlert(t("appSettings.errorMessages.invalidUrlErrorMsg"), "danger");
      setFeedUrlError(t("appSettings.errorMessages.invalidUrlErrorMsg"));
      return;
    }

    try {
      setIsUserDataLoading(true);

      // Nejprve validujeme XML feed
      const response = await axiosInstance.post("validate-xml-feed/", {
        feed: productFeedUrl,
      });

      if (response.data.status === true) {
        // Aktualizace shop feedu
        try {
          await axiosInstance.patch(`shop/update/${uuid}/`, {
            feed: productFeedUrl,
          });
          showAlert("Feed byl nahrán!", "info");
        } catch (error) {
          showAlert(
            t("appSettings.errorMessages.feedSubmissionErrorMsg"),
            "danger"
          );
          return;
        }

        // Aktualizace Google Merchant feedu
        try {
          await axiosInstance.patch("googlemerchant/account/feed/update/", {
            account_id: selectedShop.google_merchant.client_id,
            feed_name: shopForDisplay?.name,
            feed_url: productFeedUrl,
          });
        } catch (error) {
          // Pokud se pokusíme méně než 5krát, zkusíme to znovu
          if (attempt < 5) {
            return await validateFeed(null, attempt + 1);
          } else {
            // Při pátém neúspěšném pokusu zobrazíme chybovou hlášku
            showAlert(
              t("appSettings.errorMessages.feedSubmissionErrorMsg"),
              "danger"
            );
          }
        }
      } else {
        showAlert(
          t("appSettings.errorMessages.feedIsNotValidErrorMsg"),
          "danger"
        );
      }
    } catch (error) {
      showAlert(
        t("appSettings.errorMessages.feedSubmissionErrorMsg"),
        "danger"
      );
    } finally {
      setIsUserDataLoading(false);
    }
  };

  const updatePNO = async (event) => {
    event.preventDefault();
    event.target.blur();
    setIsUserDataLoading(true);

    try {
      const updatePNOResponse = await axiosInstance.patch(
        `shop/update/${
          selectedShop ? selectedShop.uuid : userData.shops[0].uuid
        }/`,
        { pno: rangeValue }
      );
      setPno(rangeValue);
      setIsUserDataLoading(false);
      showAlert(t("appSettings.infoMessages.pnoSetInfoMsg"), "info");
      if (campaignId) {
        try {
          const updateCampaignRoasResponse = await axiosInstance.patch(
            `campaign/${campaignId}/update/`,
            {
              roas:
                rangeValue === 0 ? 0 : Number((100 / rangeValue).toFixed(2)),
            }
          );
        } catch (error) {
          showAlert(t("appSettings.errorMessages.pnoUpdateErrorMsg"), "danger");
        }
      }
    } catch (error) {
      showAlert(t("appSettings.errorMessages.pnoUpdateErrorMsg"), "danger");
      setIsUserDataLoading(false);
    }
  };

  const updateLimit = async (event) => {
    event.preventDefault();
    const minLimit = currentCurrencySettings.minLimit;
    const maxLimit = currentCurrencySettings.maxLimit;
    const limit = Number(limitValue);

    if (isNaN(limit)) {
      showAlert(t("appSettings.errorMessages.invalidNumberErrorMsg"), "danger");
      return;
    }

    if (limit < minLimit) {
      showAlert(
        t("appSettings.errorMessages.limitLowErrorMsg", {
          minLimit,
          currency: currentCurrencySettings.symbol,
        }),
        "danger"
      );
      return;
    }

    if (limit > maxLimit) {
      showAlert(
        t("appSettings.errorMessages.limitExceededErrorMsg", {
          maxLimit,
          currency: currentCurrencySettings.symbol,
        }),
        "danger"
      );
      return;
    }

    setIsUserDataLoading(true);
    try {
      const updateLimitResponse = await axiosInstance.patch(
        `shop/update/${uuid}/`,
        { budget: limit }
      );
      setBudget(limit);
      setIsUserDataLoading(false);

      updateCampaign(campaignId, { budget: limit });
      showAlert(t("appSettings.infoMessages.limitSetInfoMsg"), "info");
    } catch (error) {
      showAlert(t("appSettings.errorMessages.limitUpdateErrorMsg"), "danger");
      setIsUserDataLoading(false);
    }
  };

  const onUpdateDesc = async (e) => {
    e.preventDefault();

    try {
      const onUpdateDescResponse = await axiosInstance.patch(
        `shop/update/${uuid}/`,
        {
          description: descValue,
        }
      );
      showAlert(t("appSettings.infoMessages.updateDescSuccessInfoMsg", "info"));
    } catch (error) {
      console.log("Update description error: ", error);
      showAlert(t("appSettings.errorMessages.updateDescErrorMsg"), "danger");
    }
  };

  const checkLibraryOnWebsite = async () => {
    try {
      setIsUserDataLoading(true);

      // Původní URL s protokolem
      const originalUrl = selectedShop.website;

      // Očištění URL - odstranění protokolu a subdomény
      let cleanedUrl = originalUrl.replace(/^https?:\/\//, ""); // Odstranění protokolu
      cleanedUrl = cleanedUrl.replace(/^www\./, ""); // Odstranění subdomény (www)

      // Kontrola validity pomocí očištěné URL
      const isValidUrlResponse = await fetch(
        `https://das.domena.cz/${cleanedUrl}`
      );
      const isValidUrlData = await isValidUrlResponse.json();

      // Pokud je dostupná (available true), ukončíme funkci a zobrazíme alert
      if (isValidUrlData.available) {
        showAlert(t(`appSettings.errorMessages.urlNotFoundErrorMsg`), "danger");
        return; // Ukončení funkce
      }

      // Pokud dostupná není (available false), pokračujeme s kontrolou DataPlus s původní URL
      const response = await axiosInstance.post("validate-dataplus/", {
        website: originalUrl, // Původní URL s protokolem
      });

      if (response.data.status == true) {
        showAlert(t("appSettings.infoMessages.dataplusFoundInfoMsg"), "info");
      } else {
        showAlert(
          t("appSettings.errorMessages.dataplusNotFoundErrorMsg"),
          "danger"
        );
      }
    } catch (error) {
      console.error("Chyba při kontrole knihovny:", error);
      showAlert(
        t("appSettings.errorMessages.dataplusSearchErrorMsg"),
        "danger"
      );
    } finally {
      setIsUserDataLoading(false);
    }
  };

  const toggleTooltip = (typeOfMsg) => {
    if (typeOfMsg === "pno") {
      showAlert(t("appSettings.pnoTooltip"));
    } else if (typeOfMsg === "limit") {
      showAlert(
        t("appSettings.limitTooltip", {
          budget: budget,
          budget30: (budget * 30).toLocaleString("cs-CZ"),
          currency: currentCurrencySettings.symbol,
        })
      );
    }
  };

  const onRedirect = (where, isApp = true) => {
    window.scrollTo(0, 0);
    navigate(`/${lng}/app/${where}/`);

    if (isApp) {
      setActiveItem(where);
      setAppContentType(where);
    }
  };

  // Otevření modalu při pokusu o změnu stavu
  const handleSwitcherToggle = (newStatus) => {
    setPendingStatus(newStatus); // Uloží nový stav
    setIsModalVisible(true); // Zobrazí modal
  };

  // Potvrzení změny stavu
  const confirmToggle = async () => {
    try {
      if (pendingStatus && campaignId) {
        // Aktualizace stavu kampaně pomocí API
        setIsUserDataLoading(true);
        await updateCampaign(campaignId, { campaign_status: pendingStatus });
        await axiosInstance.patch(`shop/update/${selectedShop.uuid}/`, {
          end_date: pendingStatus === "active" ? null : getDateWithNextMonth(),
        });
        const response = await axiosInstance.get(`user/detail/`);
        setUserData(response.data);
        setIsUserDataLoading(false);

        // Aktualizace stavu kampaně v aplikaci
        setCampaignStatus(pendingStatus);
        setShopStatus(pendingStatus);
      }
    } catch (error) {
      console.error("Actualization campaign error:", error);
    } finally {
      setIsUserDataLoading(false);
      setIsModalVisible(false); // Zavření modalu
      setPendingStatus(null); // Vymazání stavu
    }
  };

  // Zrušení změny stavu
  const cancelToggle = () => {
    setPendingStatus(null);
    setIsModalVisible(false);
  };

  return (
    <>
      {isModalVisible && (
        <Modal
          title="Potvrzení změny"
          onClose={cancelToggle}
          onConfirm={confirmToggle}
          confirmText={t("modalConfirmChange.accept")}
          cancelText={t("modalConfirmChange.cancel")}
        ></Modal>
      )}
      {isUserDataLoading && <ModalLoading message={message} />}
      {isAlertVisible && (
        <SimpleAlert
          message={alertMessage.text}
          onClose={closeAlert}
          type={alertMessage.type}
        />
      )}
      <article className="app-content">
        <div className={styles.header__switcher__box}>
          <h1
            style={{
              margin: 0,
            }}
          >
            {t("appSettings.shopSettings")}
          </h1>
          {campaignStatus !== "deactived" && (
            <Switcher
              campaignStatus={campaignStatus}
              onToggle={handleSwitcherToggle}
            />
          )}
        </div>
        <div className="box alert--danger">
          <p>
            <Trans
              i18nKey={t("appSettings.pnoChangeAdvice")}
              components={[
                <a
                  className="link"
                  onClick={() => onRedirect("contact-us")}
                ></a>,
              ]}
            />
          </p>
        </div>
        <div className="app-content__row">
          <div className={`box ${styles.pno_budget_set_box}`}>
            <div>
              <h2>{t("appSettings.limitTitle")}</h2>
              {budget > 0 && (
                <p>
                  <strong>
                    {t("appSettings.currentBudget", {
                      budget: budget.toLocaleString("cs-CZ"),
                      currency: currentCurrencySettings.symbol,
                    })}
                  </strong>
                </p>
              )}
              <p>
                <Trans
                  i18nKey={t("appSettings.limitExplanation")}
                  components={[<strong></strong>]}
                />
              </p>
              <form
                action="#"
                autoComplete="off"
                method="post"
                className="form"
              >
                <p className="form__currency">
                  <label htmlFor="limit">
                    <Trans i18nKey={t("appSettings.limit")} />
                    <img
                      style={{
                        display: "inline",
                        cursor: "pointer",
                        position: "relative",
                      }}
                      onClick={() => toggleTooltip("limit")}
                      src={tooltipInfoIcon}
                      ref={tooltipIconRef}
                      alt="info icon"
                    />
                  </label>
                  <input
                    type="number"
                    value={limitValue}
                    min={currentCurrencySettings.minLimit}
                    max={currentCurrencySettings.maxLimit}
                    name="limit"
                    id="limit"
                    onChange={handleLimitChange}
                  />
                  {currentCurrencySettings.symbol}
                </p>

                <p>
                  <Trans
                    i18nKey={t("appSettings.limitExplanation2")}
                    components={[<strong></strong>]}
                  />
                </p>
              </form>
            </div>
            <p>
              <button
                className="btn"
                style={{ margin: 0, marginTop: 10 }}
                onClick={(event) => updateLimit(event)}
              >
                {t("appSettings.save")}
              </button>
            </p>
          </div>
          <div className={`box ${styles.pno_budget_set_box}`}>
            <div>
              <h2 id="pno">{t("appSettings.targetPnoTitle")}</h2>
              {pno > 0 && (
                <p>
                  <strong>{t("appSettings.currentPno", { pno: pno })}</strong>
                </p>
              )}
              <p>{t("appSettings.pnoInfo")}</p>
              <form
                action="#"
                method="post"
                autoComplete="off"
                className="form"
              >
                <p className="snuggle">
                  <Trans
                    i18nKey="appSettings.maxPnoExplanation"
                    values={{
                      rangeValue: rangeValue,
                      baseAmount: (limitValue * 30).toLocaleString("cs-CZ"),
                      currency: currentCurrencySettings.symbol,
                      turnover: (() => {
                        let result = ((limitValue * 30) / rangeValue) * 100;
                        let formattedResult = new Intl.NumberFormat("cs-CZ", {
                          style: "currency",
                          currency: currency,
                        }).format(result);

                        return formattedResult.toLocaleString("cs-CZ");
                      })(),
                    }}
                  >
                    <img
                      style={{
                        display: "inline",
                        cursor: "pointer",
                        position: "relative",
                      }}
                      onClick={() => toggleTooltip("pno")}
                      src={tooltipInfoIcon}
                      ref={tooltipIconRef}
                      alt="info icon"
                    />
                  </Trans>
                </p>

                <p className="range js-range">
                  <input
                    type="range"
                    id="range"
                    name="range"
                    min="5"
                    max="50"
                    value={rangeValue}
                    step="1"
                    onChange={handleRangeChange}
                  />
                  <span className="range__values">
                    <span>5,00&nbsp;%</span>
                    <span>50,00&nbsp;%</span>
                  </span>
                </p>
              </form>
            </div>
            <p>
              <button
                className="btn"
                style={{ margin: 0, marginTop: 10 }}
                onClick={(event) => updatePNO(event)}
              >
                {pno === 0 ? t("appSettings.savePNO") : t("appSettings.save")}
              </button>
            </p>
          </div>
        </div>

        <div className="app-content__row">
          <div className="box">
            <h2
              id="install-code"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {t("appSettings.installCodeTitle")}
              <span
                className={styles.dpTutor}
                onClick={() => setShowDpTutor(true)}
              >
                ?
              </span>
            </h2>
            <p>
              <Trans
                i18nKey="appSettings.installCodeDescription"
                components={[
                  <a
                    className="link"
                    rel="noopener"
                    target="_blank"
                    href="https://dataplus.advisio.cz/?utm_source=PermoniQ&utm_medium=referrer&utm_campaign=app_settings"
                  ></a>,
                ]}
              />
            </p>
            <p style={{ margin: 0 }}>
              {t("appSettings.installCodeInstruction")}
            </p>
            <div
              className={`snuggle form__hide ${codeVisible ? "is-open" : ""}`}
            >
              <pre
                id="code"
                className={`form__code ${styles.codeBox}`}
                contentEditable
                suppressContentEditableWarning={true}
                onInput={(e) => setCode(e.currentTarget.textContent)}
                spellCheck="false"
              >
                {code || ""}
                <FaRegCopy
                  className={styles.copyIcon}
                  onClick={() => handleCodeCopy()}
                />
              </pre>
            </div>
            {/* <p className="snuggle">
              <button
                className="btn js-copy"
                onClick={(e) => {
                  handleCodeCopy();
                  e.target.blur();
                }}
                ref={copyButtonRef}
              >
                {t("appSettings.copyCode")}
              </button>
              &nbsp;&nbsp;
              <a className="link js-form-show" onClick={toggleCodeVisibility}>
                {codeVisible
                  ? t("appSettings.hideCode")
                  : t("appSettings.showCode")}
              </a>
            </p> */}
            <p>{t("appSettings.testDataplus")}</p>
            <button
              className="btn js-copy"
              onClick={(e) => {
                checkLibraryOnWebsite();
                e.target.blur();
              }}
              ref={copyButtonRef}
            >
              {t("appSettings.testDataplusBtn")}
            </button>
          </div>

          <div className="box">
            <h2 id="xml-feed">
              {t("appSettings.xmlFeedTitle")}
              <span
                className={styles.dpTutor}
                onClick={() => setShowFeedTutor(true)}
              >
                ?
              </span>
            </h2>
            <form onSubmit={validateFeed} autoComplete="off" className="form">
              <p>{t("appSettings.howToConnectFeed")}</p>
              {/* <p className="snuggle">{t('appSettings.currentFeedUrl', { feedUrl: productFeedUrl })}</p> */}
              <p>
                {t("appSettings.enterFeedUrl")}
                <br />
                <input
                  type="text"
                  value={productFeedUrl}
                  onChange={handleFeedUrlChange}
                />
              </p>
              {/* {feedUrlError && <p style={{ color: 'red' }}>{feedUrlError}</p>} */}
              <p>{t("appSettings.feedUrlNote")}</p>
              <p>
                <button className="btn" disabled={feedUrlError}>
                  {t("appSettings.save")}
                </button>
              </p>
            </form>
          </div>
        </div>
        <div className="app-content__row">
          <div className="box">
            <h2>{t("appSettings.addUserSection.title")}</h2>
            <p>
              <label htmlFor="manager">
                {t("appSettings.addUserSection.newUserMail")}
              </label>
              <input
                type="email"
                value={newUserMail}
                onChange={(e) => setNewUserMail(e.target.value)}
                placeholder="example@domain.com"
                name="manager"
                id="manager"
              />
            </p>
            <button
              className="btn"
              onClick={() => handleUserAdd(selectedShop.uuid, newUserMail)}
            >
              {t("appSettings.addUserSection.addBtn")}
            </button>
          </div>

          <div className={"box"}>
            <h2>{t("appSettings.usersListSection.title")}</h2>
            <ul className={styles.userList}>
              {selectedShop &&
                selectedShop.users?.length > 0 &&
                userList
                  .filter((user) => user.email !== "info@permoniq.com")
                  .map((user) => (
                    <li key={user.id || user.email} className={styles.userItem}>
                      <span>{user.email}</span>
                      <span
                        className={styles.deleteBtn}
                        onClick={() => {
                          setShowDeleteModal(true);
                          setUserToRemove(user);
                        }}
                      >
                        <ReactSVG src={deleteIcon} />
                      </span>
                    </li>
                  ))}
            </ul>
          </div>
        </div>
        {/* <div className="app-content__row">
          <div className="box" style={{ width: "100%" }}>
            <h2>Změna fakturačních údajů</h2>
            <div className={`${styles.billingBox}`}>
              <div>
                <p>
                  <label htmlFor="country_billing">Země pro fakturaci</label>
                  <select name="country_billing" id="country_billing">
                    {billingCountries.map((country) => {
                      return (
                        <option key={new Date().getDate() * Math.random()}>
                          {country}
                        </option>
                      );
                    })}
                  </select>
                </p>
                <p>
                  <label htmlFor="ico">Název společnosti</label>
                  <input
                    type="text"
                    value={ico}
                    onChange={(e) => setIco(e.target.value)}
                    placeholder="example@domain.com"
                    name="ico"
                    id="ico"
                  />
                </p>
                <p>
                  <label htmlFor="city">Město</label>
                  <input
                    type="text"
                    value={selectedShop?.city}
                    onChange={selectedShop?.city}
                    placeholder={selectedShop?.city}
                    name="manager"
                    id="manager"
                  />
                </p>
                <p>
                  <label htmlFor="dic">DIČ</label>
                  <input
                    type="email"
                    value={newUserMail}
                    onChange={(e) => setNewUserMail(e.target.value)}
                    placeholder="example@domain.com"
                    name="manager"
                    id="manager"
                  />
                </p>
              </div>
              <div>
                <p>
                  <label htmlFor="ico">IČO</label>
                  <input
                    type="text"
                    value={newUserMail}
                    onChange={(e) => setNewUserMail(e.target.value)}
                    placeholder="example@domain.com"
                    name="manager"
                    id="manager"
                  />
                </p>
                <p>
                  <label htmlFor="street">Ulice a číslo popisné</label>
                  <input
                    type="email"
                    value={newUserMail}
                    onChange={(e) => setNewUserMail(e.target.value)}
                    placeholder="example@domain.com"
                    name="manager"
                    id="manager"
                  />
                </p>
                <p>
                  <label htmlFor="postal_code">PSČ</label>
                  <input
                    type="email"
                    value={newUserMail}
                    onChange={(e) => setNewUserMail(e.target.value)}
                    placeholder="example@domain.com"
                    name="manager"
                    id="manager"
                  />
                </p>
                <p>
                  <label htmlFor="billing_mail">Fakturační e-mail</label>
                  <input
                    type="email"
                    value={newUserMail}
                    onChange={(e) => setNewUserMail(e.target.value)}
                    placeholder="example@domain.com"
                    name="manager"
                    id="manager"
                  />
                </p>
              </div>
            </div>

            <button
              className="btn"
              onClick={() => handleUserAdd(selectedShop.uuid, newUserMail)}
            >
              {t("appSettings.addUserSection.addBtn")}
            </button>
          </div>
        </div> */}

        {/* <div className="app-content__row">
                    <div className="box" style={{ width: "100%" }}>
                    <h2>{t('appSettings.descTitle')}</h2>
                    <form onSubmit={onUpdateDesc} autoComplete='off' className="form">
                    <label htmlFor="about-us">
                    {t("registerForm.descAboutShop")}
                    </label>
                    <textarea
                    id="about-us"
                    rows="5"
                    cols="40"
                    placeholder={t("registerForm.descPlaceholder")}
                    maxLength={maxLength}
                    minLength={350}
                    value={descValue || ""}
                    style={{ resize: "none" }}
                    onChange={(e) => setDescValue(e.target.value)}
                    />
                    <p style={{ textAlign: "right", margin: 0 }}>{350}/{maxLength} {t("appSettings.numOfLetters")}</p>
                    <p><button className="btn">{t('appSettings.sendDesc')}</button></p>
                    </form>
                    </div>
                    <sub style={{ color: 'red', fontSize: "1.5rem", marginTop: "2rem" }}>*<em style={{ color: "black", fontSize: "1rem" }}>{t("appSettings.baseAmountExplanation")}</em></sub>
                    </div> */}
      </article>
      {showDeleteModal && (
        <Modal
          title={t("appSettings.removeUserSection.title")}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={() =>
            handleUserRemove(selectedShop.uuid, userToRemove.email)
          }
          confirmText={t("appSettings.removeUserSection.confirmBtn")}
        >
          {t("appSettings.removeUserSection.removeQuestion")}
        </Modal>
      )}
      {showDpTutor && (
        <DpTutor
          onClose={() => {
            setShowDpTutor(false);
          }}
        />
      )}
      {showFeedTutor && (
        <FeedTutor
          onClose={() => {
            setShowFeedTutor(false);
          }}
        />
      )}
    </>
  );
};

export default AppSettings;
